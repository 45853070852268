import tp from '@/typo';

export default {
	video: [
		{
			src: '/video.mp4',
			title: tp.execute('Легко наверх'),
			snack: '/snack3.png',
			preview: ['/preview1.png'],
			menuPreview: '/menu-preview1.jpg',
			regions: [
				{
					start: 13.7,
					finish: 15.7,
					caption: tp.execute('Постучите по упаковке, когда герой подойдёт к двери'),
				},
			],
		},
		{
			src: '/video2.mp4',
			title: tp.execute('Кто живёт в берлоге?'),
			preview: ['/preview2.png'],
			snack: '/snack1.png',
			menuPreview: '/menu-preview2.jpg',
			regions: [
				{
					start: 11.6,
					finish: 13.6,
					caption: tp.execute('Пошуршите упаковкой, когда герой поедет через ручей'),
				},
			],
		},
		{
			src: '/video3.mp4',
			title: tp.execute('Хруст — и в зиму'),
			preview: ['/preview3.png'],
			snack: '/snack2.png',
			menuPreview: '/menu-preview3.jpg',
			regions: [
				{
					start: 3.0,
					finish: 5.5,
					caption: tp.execute('Пошуршите упаковкой, когда герой поедет по листьям'),
				},
			],
		},
	],
};
