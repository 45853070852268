export enum EVENTS {
	VIDEO_START = 'videoStart',
	VIDEO_PAUSE = 'videoPause',
	VIDEO_RESUME = 'videoResume',
	VIDEO_STOP = 'videoStop',
	PRE_RECORD_OVERLAY = 'preRecordOverlay',
	IN_RECORD_REGION = 'inRecordRegion',
	COUNTDOWN_INFORMER = 'countdownInformer',
	SET_COUNTDOWN = 'setCountdown',
	START_RECORD = 'startRecord',
	STOP_RECORD = 'stopRecord',
	REGIONS_PROGRESS_INFORMER = 'regionsProgressInformer',
	NEXT_REGION = 'nextRegion',
	ADD_RECORDED_REGION = 'addRecordedRegion',
	PRE_FINAL_OVERLAY = 'preFinalOverlay',
	MICROPHONE_TEST_DONE = 'microphoneTestDone',
	MICROPHONE_TEST_FAILED = 'microphoneTestFailed',
	SELECT_VIDEO = 'selectVideo',
	PROMO_PAGE = 'promoPage',
	FINAL_VIDEO = 'finalVideo',
	TOGGLE_SOUND = 'toggleSound',
	BACK_TO_SELECT_VIDEO = 'backToSelectVideo',
	RESTART_RECORD = 'restartRecord',
	VIDEO_MENU_OVERLAY = 'videoMenuOverlay',
	BACK_TO_SHARED_VIDEO_LANDING = 'backToSharedVideoLanding',
	SHARED_VIDEO = 'sharedVideo',
	TOGGLE_SHARED_VIDEO_MENU = 'toggleSharedVideoMenu',
	TOGGLE_PLAY_VIDEO_MENU = 'togglePlayVideoMenu',
	TEST_LAST_RECORD = 'testLastRecord',
	RESTART_MICROPHONE_TEST = 'restartMicrophoneTest',
	UPDATE_RECORDED_REGIONS = 'updateRecordedRegions',
	ATTENTION_POPUP = 'attentionPopup',
	ACCEPT_ATTENTION = 'acceptAttention',
	CLOSE_POPUP = 'closePopup',
	PROMO_POPUP = 'promoPopup',
	SHARE_POPUP = 'sharePopup',
	ACCEPT_ATTENTION_QR = 'acceptAttentionQR',
	ATTENTION_QR_POPUP = 'attentionQRPopup',
	FINAL_VIDEO_MENU = 'finalVideoMenu',
	PERMISSION_ALLOWED = 'permissionAllowed',
	PERMISSION_DENIED = 'permissionDenied',
}
