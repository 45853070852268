import './app.scss';
import App from './App.svelte';
import { registerWav } from '@/utils';
import BugsnagPerformance from '//d2wy8f7a9ursnm.cloudfront.net/v1/bugsnag-performance.min.js';
import {
	currentPlayer,
	currentVideoIndex,
	currentVideoPart,
	recordedParts,
	savedVideosList,
} from '@/stores';
import { get } from 'svelte/store';
import RecordController from '@/lib/partials/recordController';

registerWav();

const app = new App({
	target: document.getElementById('app')!,
});

window.Bugsnag.start({
	apiKey: 'c0234c5b8fa029d4705cbd899a34b564',
	appVersion: '1.1.0-rc.7',
	onError: event => {
		event.addMetadata('stores', {
			stream: RecordController.getInstance().stream,
			audioContext: RecordController.getInstance().audioContext,
			currentVideoIndex: get(currentVideoIndex),
			currentVideoPart: get(currentVideoPart),
			recordedParts: get(recordedParts),
			savedVideosList: get(savedVideosList),
		});
	},
});
BugsnagPerformance.start({ apiKey: 'c0234c5b8fa029d4705cbd899a34b564' });

export default app;
