<script lang='ts'>
	import Screen from '@/lib/components/Screen.svelte';
	import {
		countDownInformer,
		currentVideoIndex,
		currentVideoPart,
		inRecordRegion,
		microphoneTestDone,
		popup,
		recordActivated,
		recordInformer,
		recordingInfoBackground,
		regionsProgressInformer,
	} from '@/stores';
	import VideoPlayer from '@/lib/components/VideoPlayer.svelte';
	import Plyr from 'plyr';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import videoConfig from '@/video.config';
	import { get } from 'svelte/store';
	import EventEmitter from '@/lib/partials/eventEmitter';
	import { onDestroy, onMount } from 'svelte';
	import RecordController from '@/lib/partials/recordController';
	import CountDownInformer from '@/lib/components/CountDownInformer.svelte';
	import RecordingInfoBackground from '@/lib/components/RecordingInfoBackground.svelte';
	import RecordingInfo from '@/lib/components/RecordingInfo.svelte';
	import RegionsProgressInformer from '@/lib/components/RegionsProgressInformer.svelte';
	import RecordPreview from '@/lib/components/RecordPreview.svelte';

	let player: Plyr;
	const showFinalVideo = () => {
		destroy();
		eventBus.emit(EVENTS.PRE_FINAL_OVERLAY, true);
	};
	const microphoneTest = () => {
		eventBus.emit(EVENTS.VIDEO_START);
	};

	let loopCancelCallback;
	let localEventBus = new EventEmitter();
	eventBus.pipe(localEventBus);

	const play = () => loopCancelCallback = RecordController.getInstance().startLoop(updateRecordState);
	const stop = () => {
		loopCancelCallback && loopCancelCallback();
	};
	const updateRecordState = () => {
		const { currentTime } = player;
		const { start, finish } =
			videoConfig.video[get(currentVideoIndex)].regions[get(currentVideoPart)];

		if (currentTime < start) {
			if (start - currentTime <= 4 && !get(inRecordRegion)) {
				eventBus.emit(EVENTS.VIDEO_PAUSE);
				eventBus.emit(EVENTS.PRE_RECORD_OVERLAY, true);
			}

			if (start - currentTime <= 3 && get(inRecordRegion)) {
				eventBus.emit(EVENTS.COUNTDOWN_INFORMER, true);
				eventBus.emit(EVENTS.SET_COUNTDOWN, Math.ceil(start - currentTime));
			}
		}

		if (currentTime >= start && currentTime < finish && !get(recordActivated)) {
			eventBus.emit(EVENTS.COUNTDOWN_INFORMER, false);
			eventBus.emit(EVENTS.START_RECORD);
		}

		if (currentTime >= finish && get(recordActivated)) {
			eventBus.emit(EVENTS.TEST_LAST_RECORD);
			eventBus.emit(EVENTS.STOP_RECORD);
		}

		if (currentTime >= finish + 1 && get(inRecordRegion)) {
			eventBus.emit(EVENTS.IN_RECORD_REGION, false);
			eventBus.emit(EVENTS.NEXT_REGION);
			eventBus.emit(EVENTS.REGIONS_PROGRESS_INFORMER, false);
			eventBus.emit(EVENTS.VIDEO_STOP);
			showFinalVideo();
		}
	};

	localEventBus.on(EVENTS.VIDEO_START, play);
	localEventBus.on(EVENTS.VIDEO_RESUME, play);
	localEventBus.on(EVENTS.VIDEO_PAUSE, stop);
	localEventBus.on(EVENTS.VIDEO_STOP, stop);

	onMount(async () => {
		window.Bugsnag.notify('record test');

		try {
			await RecordController.getInstance().getUserMedia();
			microphoneTest();
			eventBus.emit(EVENTS.PERMISSION_ALLOWED);
		} catch (e) {
			eventBus.emit(EVENTS.PERMISSION_DENIED);
			throw e;
		}

		// player.on('ended', showFinalVideo);
	});

	const destroy = () => {
		// player.off('ended', showFinalVideo);
		eventBus.unpipe(localEventBus);
		stop();
	};

	onDestroy(() => {
		destroy();
	});

	const showMenu = () => {
		if (get(inRecordRegion)) return;

		eventBus.emit(EVENTS.VIDEO_PAUSE);
		eventBus.emit(EVENTS.VIDEO_MENU_OVERLAY, true);
	};
</script>

<Screen bg-gray>
	<VideoPlayer on:click={showMenu} bind:player={player} />
	<RecordPreview />

	{#if $recordingInfoBackground}
		<RecordingInfoBackground />
	{/if}

	{#if $countDownInformer}
		<CountDownInformer />
	{/if}

	{#if $recordInformer}
		<RecordingInfo />
	{/if}

	{#if $regionsProgressInformer}
		<RegionsProgressInformer />
	{/if}
</Screen>
