<script lang='ts'>
	import Popup from '@/lib/components/Popup.svelte';
	import { onDestroy, onMount } from 'svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import texts from '@/texts';
	import Button from '@/lib/components/Button.svelte';

	let localEventBus;

	const buttonAction = async () => location.reload();

	onMount(async () => {
	});

	onDestroy(() => {
	});
</script>

<Popup class='microphone-test'>
	<div class='_title'>
		<Text -popup-title -center -bold>
			{@html texts.popup.denied.title}
		</Text>
	</div>
	<div class='_caption'>
		<Text -popup-caption -center>
			{@html texts.popup.denied.caption}
		</Text>
	</div>
	<div class='_button'>
		<Button on:click={buttonAction}>{@html texts.popup.denied.button}</Button>
	</div>
</Popup>

<style lang='scss'>
  ._title {
    margin-top: 20px;
  }

  ._caption {
    margin-top: 27px;
    margin-bottom: 8px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(37px);
    }
  }
</style>

