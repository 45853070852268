<script lang='ts'>
	import Popup from '@/lib/components/Popup.svelte';
	import { onDestroy, onMount } from 'svelte';
	import Text from '@/lib/components/typo/Text.svelte';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import RecordController from '@/lib/partials/recordController';
	import { RMSAnalyzer } from '@/lib/partials/RMSAnalyzer';
	import texts from '@/texts';
	import EventEmitter from '@/lib/partials/eventEmitter';

	let localEventBus;
	let canvas: HTMLCanvasElement;
	let timeoutID;
	let baseCanvasH = 160;

	const createAnalyzer = async () => {
		const rec = RecordController.getInstance();
		rec.addToCanvas(canvas);

		await rec.showAnalyser();

		const rmsAnalyzer = new RMSAnalyzer(RecordController.getInstance().stream);

		localEventBus = new EventEmitter();
		localEventBus.once(EVENTS.MICROPHONE_TEST_DONE, () => rec.removeAnalyser());
		eventBus.pipe(localEventBus);

		rmsAnalyzer.onFineSignal().then(() => {
			clearTimeout(timeoutID);
			setTimeout(() => eventBus.emit(EVENTS.MICROPHONE_TEST_DONE), 1000);
		});

		timeoutID = setTimeout(() => {
			eventBus.emit(EVENTS.MICROPHONE_TEST_FAILED);
		}, 10000);
	};

	onMount(async () => {
		let desktopMatch = matchMedia('(orientation: landscape) and (min-width: 1001px)').matches;
		let canvasWidth = canvas.parentElement?.offsetWidth;
		let canvasHeight = (matchMedia('(min-width: 1001px)').matches ? desktopMatch ? 130 : 325 : baseCanvasH);

		canvas.width = canvasWidth * window.devicePixelRatio;
		canvas.height = canvasHeight * window.devicePixelRatio;
		canvas.style.width = canvasWidth + 'px';
		canvas.style.height = canvasHeight + 'px';

		if (RecordController.getInstance().stream) {
			await createAnalyzer();
		} else {
			eventBus.once(EVENTS.PERMISSION_ALLOWED, () => createAnalyzer());
		}
	});

	onDestroy(() => {
		eventBus.unpipe(localEventBus);
		clearTimeout(timeoutID);
	});
</script>

<Popup class='microphone-test'>
	<canvas data-base-h='{baseCanvasH*window.devicePixelRatio}' bind:this={canvas}></canvas>
	<div class='_title'>
		<Text -popup-title -center -bold>
			{@html texts.popup.testMicro.title}
		</Text>
	</div>
	<div class='_caption'>
		<Text -popup-caption -center>
			{@html texts.popup.testMicro.caption}
		</Text>
	</div>
</Popup>

<style lang='scss'>
  ._title {
    margin-top: 20px;
  }

  ._caption {
    margin-top: 27px;
    margin-bottom: 8px;

    @include breakpoint-up($tablet) {
      margin-top: px-to-rem-tablet(37px);
    }
  }
</style>

