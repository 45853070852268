<script lang='ts'>
	import { onDestroy, onMount } from 'svelte';
	import Plyr from 'plyr';
	import eventBus from '@/eventBus';
	import { EVENTS } from '@/enums';
	import videoConfig from '@/video.config';
	import { currentPlayer, currentVideoIndex, recordedParts, sound } from '@/stores';
	import { Howl } from 'howler';
	import { get } from 'svelte/store';
	import RecordController from '@/lib/partials/recordController';
	import EventEmitter from '@/lib/partials/eventEmitter';
	import { assetsUrl } from '@/utils';

	export let player: Plyr;
	export let final: boolean = false;

	let localEventBus;
	let video;
	let recordedSounds: Howl[] = [];
	let currentVideoParts = [...videoConfig.video[$currentVideoIndex].regions];
	let playedRecords: number[] = [];
	let loopCancelCallback;

	const destroyLoop = () => {
		loopCancelCallback && loopCancelCallback();
	};
	const startLoop = (progress) => {
		loopCancelCallback && loopCancelCallback();
		loopCancelCallback = RecordController.getInstance().startLoop(progress);
	};

	onMount(async () => {
		player = new Plyr(video, {
			controls: [],
			fullscreen: {
				iosNative: false,
				enabled: false,
			},
			clickToPlay: false,
			volume: get(sound) ? 1 : 0,
		});

		player.volume = get(sound) ? 1 : 0;
		currentPlayer.set(player);

		localEventBus = new EventEmitter();
		localEventBus.once(EVENTS.VIDEO_START, player.play);
		localEventBus.on(EVENTS.VIDEO_PAUSE, player.pause);
		localEventBus.on(EVENTS.VIDEO_RESUME, player.play);
		localEventBus.on(EVENTS.START_RECORD, () => player.volume = 0);
		localEventBus.on(EVENTS.STOP_RECORD, () => {
			if (get(sound)) {
				player.volume = 1;
			}
		});
		localEventBus.on(EVENTS.TOGGLE_SOUND, (val: boolean) => {
			player.volume = val ? 1 : 0;
		});

		eventBus.pipe(localEventBus);

		if (final) {
			for (let [, url] of $recordedParts[$currentVideoIndex].entries()) {
				recordedSounds.push(new Howl({
					src: [url],
					format: 'wav',
					preload: true,
					volume: 1,
				}));
			}
		}

		const progress = () => {
			if (final && recordedSounds.length > 0) {
				for (let [index, { start, finish }] of currentVideoParts.entries()) {
					const sound = recordedSounds[index];

					if (player.currentTime >= start && !playedRecords.includes(index)) {
						playedRecords.push(index);
						sound.play();
					}
				}
			}
		};

		player.on('play', () => startLoop(progress));
		player.on('pause', destroyLoop);
		player.on('ended', destroyLoop);
	});

	onDestroy(() => {
		destroyLoop();
		player.destroy();
		eventBus.unpipe(localEventBus);
	});
</script>

<div class='video-preview' on:click on:mousemove>
	<video playsinline bind:this={video} src='{assetsUrl(videoConfig.video[$currentVideoIndex].src)}'></video>
</div>

<style lang='scss'>
  :global(.plyr) {
    width: 100%;
    height: 100%;
  }

  .video-preview {
    margin: auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    @include desktop-breakpoint-up() {
      height: 100%;
      aspect-ratio: 10 / 16;
      border-radius: 10px;
      overflow: hidden;
    }

    :global(video) {
      object-fit: cover;
    }
  }
</style>
