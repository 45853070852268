<script lang='ts'>
	import { recordedParts, screen } from '@/stores';
	import Text from '@/lib/components/typo/Text.svelte';
	import Triangle from '@/assets/Triangle.svelte';
	import { isVideoRecorded } from '@/utils';
	import ImageSlides from '@/lib/components/ImageSlides.svelte';
	import Image from '@/lib/components/Image.svelte';

	export let video: { title: string; preview: string, snack: string };
	export let index: number;

	const recorded = isVideoRecorded(index);
</script>

{#if video}
	<div class='video-preview' on:click={()=>recorded ? screen.playVideo(index) : screen.record(index)}>
		<div class='preview'>
			<ImageSlides images='{video.preview}' />
			{#if recorded}
				<div class='overlay'>
					<a href='{$recordedParts[index][0]}'>Слушать</a>
				</div>
			{/if}
		</div>
		<div class='_title'>
			<Text -video-preview-title -center -white -bold>{@html video.title}</Text>
		</div>
		{#if !recorded}
			<div class='snack'>
				<Image src='{video.snack}' />
			</div>
		{/if}
	</div>
{/if}

<style lang='scss'>
  .video-preview {
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    .preview {
      width: 100%;
      height: 100%;
      min-height: 200px;
      position: relative;
      border-radius: 20px;
      border: 1px solid $black;
      overflow: hidden;
      box-sizing: border-box;
      cursor: pointer;

      .overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(#404040, .8);

        .circle {
          width: 70px;
          height: 70px;
          padding-left: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 70px;
          border: 1px solid $black;
          background: $beige-a;
          box-sizing: border-box;

          @include breakpoint-up($tablet) {
            width: px-to-rem-tablet(130px);
            height: px-to-rem-tablet(130px);
            border-radius: px-to-rem-tablet(130px);
          }
        }
      }
    }

    ._title {
      margin-top: 9px;

      @include breakpoint-up($tablet) {
        margin-top: 14px;
      }
    }

    .snack {
      width: 120px;
      height: 120px;
      position: absolute;
      right: 0;
      bottom: 10px;

      :global(img) {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @include breakpoint-up($tablet) {
        width: px-to-rem-tablet(288px);
        height: px-to-rem-tablet(288px);
        bottom: px-to-rem-tablet(-40px);
      }
    }
  }
</style>
