import tp from '@/typo';

export default {
	main: {
		title: tp.execute('Звучит как приключение'),
		caption: tp.execute(
			'Помогите доставить заказы в сказочный мир — а заодно попробуйте себя в озвучке настоящих мультфильмов.' +
				'<br/>' +
				'<br/>' +
				'В конце — подарим промокоды на скидку.',
		),
		captionOffline: tp.execute(
			'Помогите доставить заказы в сказочный мир — а заодно попробуйте себя в озвучке настоящего мультфильма.' +
				'<br/>' +
				'<br/>' +
				'В конце — подарим промокод в Самокат.',
		),
		list: {
			online: [
				{
					img: '/bullet1.png',
					text: tp.execute(
						'Возьмите мармелад, сок и снеки от Самоката — или другие продукты, которые есть рядом',
					),
				},
				{
					img: '/bullet2.png',
					text: tp.execute('Следуйте подсказкам на экране'),
				},
				{
					img: '/bullet3.png',
					text: tp.execute('Заберите промокоды на покупки и поделитесь результатом с друзьями'),
				},
			],
			offline: [
				{
					img: '/bullet1.png',
					text: tp.execute('Подготовьте продукт, который взяли на стойке'),
				},
				{
					img: '/bullet2.png',
					text: tp.execute('Следуйте подсказкам на экране'),
				},
				{
					img: '/bullet3.png',
					text: tp.execute('Сохраните результат и заберите промокод на покупки'),
				},
			],
		},
	},
	selectVideo: {
		title: tp.execute('Нажмите на мультфильм, который хотите озвучить'),
	},
	popup: {
		testMicro: {
			title: tp.execute('Теперь — проверим<br/>' + 'звук'),
			caption: tp.execute('Попробуйте пошуршать<br/>' + 'или постучать упаковкой'),
		},
		testDone: {
			caption: tp.execute('Всё получилось! Можно смотреть мультфильм и записывать звук'),
		},
		testFailed: {
			title: tp.execute('Ой, не записалось!'),
			caption: tp.execute(
				'Похоже, что-то не так с микрофоном. Проверьте настройки и попробуйте снова',
			),
			button: tp.execute('Записать ещё раз'),
		},
		denied: {
			title: tp.execute('Ой!'),
			caption: tp.execute('В этой игре нужно озвучивать мультики, поэтому без микрофона никак'),
			button: tp.execute('Дать доступ'),
		},
		attention: {
			title: tp.execute('Мультфильм с вашей озвучкой не сохранится. Хотите продолжить?'),
		},
		share: {
			title: tp.execute('Чтобы поделиться мультиком с друзьями нужно скопировать ссылку:'),
		},
		attentionQR: {
			title: tp.execute('Вы сохранили мультфильм с вашей озвучкой?'),
		},
	},
	finalVideo: {
		offlineTitle: tp.execute(
			'Классно звучите! Мультфильм с вашей озвучкой — готов.<br/><br/>Посмотрите, что получилось. Результат можно сохранить или перезаписать.',
		),
		titles: [
			tp.execute(
				'Классно звучите! Первый мультфильм с вашей озвучкой — готов.<br/><br/>Посмотрите, что получилось. Результат можно сохранить или перезаписать.',
			),
			tp.execute(
				'Так и запишем! Второй мультфильм с вашей озвучкой — тоже есть.<br/><br/>Посмотрите, что получилось. Результат можно сохранить или перезаписать.',
			),
			tp.execute(
				'Получилось звучно! Записали все мультфильмы с вашей озвучкой.<br/><br/>Посмотрите, что получилось. Результат можно сохранить или перезаписать.',
			),
		],
		title: tp.execute(
			'Ура, мультфильм озвучен!<br/>' +
				'Теперь можно просмотреть результат и сохранить его либо перезаписать.',
		),
		watchButton: tp.execute('Смотреть'),
		menu: {
			cancel: tp.execute('Озвучить\n' + 'заново'),
			accept: tp.execute('Сохранить'),
		},
	},
	legal: {
		offline: tp.execute(
			'6+ ООО «Умный ритейл», г. Москва, ул. Барклая, д. 6, стр. 3, помещ. 8Н/4, ОГРН 1177847261602 Акция действует с 07.11.2024 по 15.11.2024. <a href="https://terms.samokat.ru/promo/Pravila_Mult_Offline.pdf" target="_blank">"Правила акции"</a>. <br/>© ООО "Умный ритейл", 2024',
		),
		online: tp.execute(
			'6+ ООО «Умный ритейл», г. Москва, ул. Барклая, д. 6, стр. 3, помещ. 8Н/4, ОГРН 1177847261602 Акция действует с&nbsp;07.11.2024 по&nbsp;01.12.2024. <a href="https://terms.samokat.ru/promo/Pravila_Mult.pdf" target="_blank">"Правила акции"</a><br/>© ООО "Умный ритейл", 2024',
		),
	},
	madeByGroznov: tp.execute('Сделано в <a href="https://groznov.co/" target="_blank">GROZNOV</a>'),
	promo: {
		titles: [
			tp.execute('Сохранили мультфильм с вашей озвучкой. За неё дарим промокод:'),
			tp.execute('Ура, второй мультфильм тоже готов! Вот ещё один промокод:'),
			tp.execute('Здорово, вы озвучили все мультфильмы! А вот – последний промокод:'),
		],
		title: tp.execute('Сохранили мультфильм с вашей озвучкой. За неё дарим промокод:'),
		captionOffline: 'Он даст скидку 20&thinsp;% на товары бренда Самокат от 1000&thinsp;₽',
		next: tp.execute('Озвучить ещё мультфильм'),
		share: tp.execute(
			'Чтобы посмотреть готовый мультфильм или поделиться с друзьями, скопируйте эту ссылку:',
		),
		shareOffline: tp.execute(
			'Чтобы <span style="color:#FF335F;">сохранить мультфильм и промокод</span>, нужно отсканировать QR-код:',
		),
		closeOffline: tp.execute('Выйти'),
		toApp: tp.execute('За покупками'),
		captions: [
			'Он даст скидку 10&thinsp;% на товары бренда Самокат от 1000&thinsp;₽',
			'Он даст скидку 15&thinsp;% на товары бренда Самокат от 1000&thinsp;₽',
			'Он даст скидку 20&thinsp;% на товары бренда Самокат от 1000&thinsp;₽',
		],
	},
	menu: {
		title1: tp.execute('Мои мультики:'),
		title2: tp.execute('Мои промокоды:'),
	},
	sharedQR: {
		title: tp.execute('Промокод за вашу озвучку:'),
	},
	sharedVideo: {
		title: tp.execute('Это — мультфильм с моей озвучкой:'),
		caption: tp.execute('Попробуй, у тебя тоже получится! В конце — подарят промокод в Самокат'),
	},
	P404: {
		title: tp.execute('Кажется, такой<br/>' + 'страницы нет'),
	},
	toAppLink:
		'https://vml8.adj.st/main?showcaseType=MINIMARKET&adj_t=10oehtvh&adj_campaign=mult_factory&adj_adgroup=go_shopping',
	cookie: tp.execute(
		'На этом сайте мы используем cookie, чтобы всё работало как надо, а аналитика сайта соответствовала <a href="https://samokat.ru/privacy" target="_blank">Политике конфиденциальности</a>',
	),
};
